@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Candal";
  src: url("font/candal.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "OpenSauce";
  src: url("font/opensauce.ttf") format("truetype");
  font-style: normal;
}